import puolueet from '../Filters/puolueet.json';
import ammattiliitot from '../Filters/liitot.json';

// eslint-disable-next-line import/prefer-default-export
export const filterCandidate = (candidate, filters) => {
  const { puolue, liitto, haku, kunta } = filters;

  const pv = puolue === 'Kaikki' ? null : puolue;
  const lv = liitto === 'Kaikki' ? null : liitto;

  const result = [candidate]
    .filter(({ puolue: candidatePuolue }) => {
      const puolueData = puolueet[pv];
      if (puolueData) {
        // puolueData is like {"fi": "Kansallinen Kokoomus", "sv": "Samlingspartiet", "en": "National Coalition Party"}
        // we need to check all the translations
        const translations = Object.values(puolueData);
        return translations.includes(candidatePuolue);
      }
      return pv ? pv === candidatePuolue : true;
    })
    .filter(({ ammattiliitto }) => {
      const liittoData = ammattiliitot[lv];
      if (liittoData) {
        // liittoData is like {"fi": "JHL", "sv": "JHL", "en": "JHL"}
        // we need to check all the translations
        const translations = Object.values(liittoData);
        return translations.includes(ammattiliitto);
      }
      return lv ? lv === ammattiliitto : true;
    })
    .filter(({ title }) =>
      haku
        ? haku.length > 2 &&
          title.rendered.toLowerCase().includes(haku.toLowerCase())
        : true
    )
    .filter(({ kunta: candidateKunta }) =>
      kunta ? kunta.includes(candidateKunta) : true
    );
  return result.length > 0;
};

export const sortCandidate = (a, b) => {
  // Get lastname from title (Firstname Lastname)
  // NOTE: name can also be "TwoPart Firstname Lastname"
  const aNameParts = a.title.rendered.split(' ');
  const aLastName = aNameParts[aNameParts.length - 1];
  const bNameParts = b.title.rendered.split(' ');
  const bLastName = bNameParts[bNameParts.length - 1];
  // Sort by lastname
  if (aLastName < bLastName) {
    return -1;
  }
  if (aLastName > bLastName) {
    return 1;
  }
  return 0;
};
