import { SET_FILTERS } from './actions';

export const initialState = {
  puolue: null,
  liitto: null,
  haku: null,
  kunta: null,
  maxValue: 9,
  minValue: 0,
  currentPage: 1,
};
// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTERS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default reducer;
