import { createSelector } from 'reselect';

export const getCandidates = (state) => state.candidates || [];

export const getCandidateBySlug = createSelector(
  [getCandidates, (state, slug) => slug],
  (candidates, slug) => {
    if (!Array.isArray(candidates)) return null;
    return candidates.find((c) => c.slug === slug);
  }
);
