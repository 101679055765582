// eslint-disable-next-line import/prefer-default-export
export const ROW_GUTTER = { xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 48 };
export const BOX_SHADOW =
  '0px 12px 17px rgba(2, 3, 3, 0.03), 0px 5px 22px rgba(2, 3, 3, 0.02), 0px 7px 8px rgba(2, 3, 3, 0.04)';

const getElectionAppValue = (key, defaultValue = '') =>
  (window && window.electionApp && window.electionApp[key]) || defaultValue;

export const CURRENT_LANG = getElectionAppValue('currentLang', 'fi');
export const NAME_SEARCH = getElectionAppValue('pllName', 'Nimihaku');
export const MUNICIPALITY_SEARCH = getElectionAppValue(
  'pllMunicipality',
  'Kuntahaku'
);
export const MUNICIPALITY_SEARCH_HOLDER = getElectionAppValue(
  'pllMunicipalityHolder'
);
export const PARTY_FILTER = getElectionAppValue('pllPartyFilter', 'Puolue');
export const CHOOSE_PARTY = getElectionAppValue(
  'pllChooseParty',
  'Valitse puolue'
);
export const ALL = getElectionAppValue('pllAll', 'Kaikki');
export const UNION_FILTER = getElectionAppValue(
  'pllUnionFilter',
  'Ammattiliitto'
);
export const CHOOSE_UNION = getElectionAppValue(
  'pllChooseUnion',
  'Valitse ammattiliitto'
);
export const SHARE_TEXT = getElectionAppValue('pllShare', 'Jaa');
export const SHARE_LINK = getElectionAppValue(
  'pllShareLink',
  'Jaa somessa tai kopioi linkki'
);
export const INTRO_TEXT = getElectionAppValue(
  'pllIntroduction',
  'Esittelyteksti'
);
export const INTRO_TEXT_SV = getElectionAppValue(
  'pllIntroductionSv',
  'Presentationstext'
);
export const INTRO_TEXT_EN = getElectionAppValue(
  'pllIntroductionEn',
  'Introduction text'
);
export const COPY_LINK = getElectionAppValue('pllCopyLink', 'Kopioi linkki');
export const LINK_COPIED = getElectionAppValue(
  'pllLinkCopied',
  'Linkki on kopioitu leikepöydälle'
);
export const THANKS_TEXT = getElectionAppValue('pllThanks', 'Kiitos');
export const PRINT_FILENAME = getElectionAppValue(
  'pllPrintFileName',
  'Ehdokastuloste'
);
export const WP_ENV = getElectionAppValue('wpEnv');
export const NO_RESULTS_TEXT = getElectionAppValue(
  'pllNoResults',
  'Ei hakuehtoja vastaavia tuloksia'
);
export const GO_BACK_TEXT = getElectionAppValue(
  'pllGoBack',
  'Takaisin hakutuloksiin'
);
