/* eslint-disable no-console */
import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { LOAD_CANDIDATES, SAVE_CANDIDATES } from './actions';

// URL and post per page
const baseUrl = '/wp-json/wp/v2/posts';
const perPage = '?per_page=100';
const ehdokkaanaKuntavaaleissa = 'olen_ehdokkaana_kuntavaaleissa=true';
const options = {
  wpFetchHeaders: {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Expose-Headers': 'x-wp-total',
    },
  },
};

async function getNumCandidates() {
  const { headers } = await axios(
    `${baseUrl}${perPage}&${ehdokkaanaKuntavaaleissa}`,
    options.wpFetchHeaders
  );
  return headers['x-wp-totalpages'];
}

// Loop all pages and store candidates
async function fetchPosts(numPages) {
  const posts = [];

  for (let page = 1; page <= numPages; page += 1) {
    const post = axios.get(
      `${baseUrl}${perPage}&page=${page}&${ehdokkaanaKuntavaaleissa}`,
      options.wpFetchHeaders
    );
    posts.push(post);
  }

  const response = await axios.all(posts);
  const postData = response.map((res) => res.data);
  const result = postData.flat();

  return result;
}

// Get all candidates
async function getCandidates() {
  const numPosts = await getNumCandidates();
  const posts = await fetchPosts(numPosts);

  return posts;
}

// Set candidates to payload
export function* fetchCandidates() {
  try {
    const response = yield call(getCandidates);

    yield put({ type: SAVE_CANDIDATES, payload: response });
  } catch (err) {
    console.log(err.message);
  }
}

export default function* sagas() {
  yield takeEvery(LOAD_CANDIDATES, fetchCandidates);
}
