// TODO: use actual names of the colors
// TODO: add more colors
const colors = {
  red: '#F8462D',
  gray01: '#F5F5F5',
  gray04: '#F0F0F0',
  gray05: '#D9D9D9',
  gray06: '#BFBFBF',
  gray08: '#595959',
  gray09: '#262626',
  sakBlue: '#D0CEB7',
};

module.exports = colors;
