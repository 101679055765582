import { Col, Select, Row } from 'antd';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@namia/typography';
import { setFilters } from './actions';
import { useQuery } from '../../helpers';
import {
  ROW_GUTTER,
  PARTY_FILTER,
  UNION_FILTER,
  CHOOSE_UNION,
  CHOOSE_PARTY,
  CURRENT_LANG,
  ALL,
} from '../../constants';
import Search from './Search';
import { updateSearchParams } from '../Share/helpers';
import { FilterSection } from './styles';

import liitot from './liitot.json';
import puolueet from './puolueet.json';

const { Option } = Select;

const sortPartiesByCurrentLanguage = (a, b) => {
  // if value is "Muu", it should always be last
  if (a === 'Muu') {
    return 1;
  }
  const aName = puolueet[a][CURRENT_LANG];
  const bName = puolueet[b][CURRENT_LANG];
  return aName.localeCompare(bName);
};

const sortUnionsByCurrentLanguage = (a, b) => {
  // if value is "Olen töissä SAK:ssa tai SAK:laisessa ammattiliitossa", it should always be last
  if (a === 'Olen töissä SAK:ssa tai SAK:laisessa ammattiliitossa') {
    return 1;
  }
  const aName = liitot[a][CURRENT_LANG];
  const bName = liitot[b][CURRENT_LANG];
  return aName.localeCompare(bName);
};
/* eslint no-nested-ternary: */
function Filters() {
  const dispatch = useDispatch();

  const { haku, kunta, puolue, liitto } = useSelector((state) => state.filters);
  // Use URL query to set initial values.
  const query = useQuery();
  const {
    haku: searchParam,
    kunta: kuntaParam,
    puolue: puolueParam,
    liitto: liittoParam,
    print,
  } = query;

  const defaultPuolue = puolueParam || puolue;

  const defaultLiitto = liittoParam || liitto;
  const defaultSearch = searchParam || haku;
  const defaultKunta = kuntaParam || kunta;

  const [puolueValue, setPuolueValue] = useState(defaultPuolue);
  const [liittoValue, setLiittoValue] = useState(defaultLiitto);
  const [searchValue, setSearchValue] = useState(defaultSearch);
  const [kuntaValue, setKuntaValue] = useState(defaultKunta);

  const resetPaging = useCallback(() => {
    dispatch(
      setFilters({
        maxValue: 9,
        minValue: 0,
        currentPage: 1,
      })
    );
  }, [dispatch]);

  // Handle Filters change.
  useEffect(() => {
    // Set filters to store.
    dispatch(
      setFilters({
        puolue: puolueValue,
        liitto: liittoValue,
        haku: searchValue,
        kunta: kuntaValue,
      })
    );
    updateSearchParams({
      filters: {
        haku: searchValue,
        kunta: kuntaValue,
        puolue: puolueValue,
        liitto: liittoValue,
      },
    });
  }, [puolueValue, searchValue, kuntaValue, liittoValue, dispatch]);

  const { matches } = window.matchMedia('print');
  const { printMode } = useSelector((state) => state.share);
  const isPrint = matches || printMode;
  if (isPrint) {
    return (
      <div>
        <div>
          <strong>{haku}</strong>
        </div>
        <div>
          <strong>{kunta}</strong>
        </div>
        <div>
          <strong>{puolue}</strong>
        </div>
        <div>
          <strong>{liitto}</strong>
        </div>
      </div>
    );
  }
  return (
    <Row gutter={[ROW_GUTTER, ROW_GUTTER]}>
      <Col xs={22} md={7}>
        <Search
          setParentSearchValue={(value) => {
            if (value && value !== searchValue) {
              resetPaging();
            }
            setSearchValue(value);
          }}
          defaultSearchValue={searchValue}
          defaultKuntaValue={kuntaValue}
          setParentKuntaValue={(value) => {
            if (value && value !== kuntaValue) {
              resetPaging();
            }
            setKuntaValue(value);
          }}
        />
      </Col>
      <Col xs={22} md={7}>
        {!print && (
          <FilterSection>
            <Typography type="basic" elementType="p" size="1" uppercase>
              {PARTY_FILTER}
            </Typography>
          </FilterSection>
        )}
        <Select
          size="large"
          value={puolueValue || CHOOSE_PARTY}
          allowClear
          onClear={() => {
            resetPaging();
            setPuolueValue('Kaikki');
          }}
          onChange={(value) => {
            if (value && value !== puolueValue) {
              resetPaging();
            }
            setPuolueValue(value);
          }}
          style={{ width: '100%' }}
        >
          <Option value="Kaikki">{ALL}</Option>
          {Object.keys(puolueet)
            .sort(sortPartiesByCurrentLanguage)
            .map((p) => (
              <Option value={p}>
                {CURRENT_LANG === 'fi'
                  ? puolueet[p].fi
                  : CURRENT_LANG === 'sv'
                    ? puolueet[p].sv
                    : puolueet[p].en}
              </Option>
            ))}
        </Select>
      </Col>

      <Col xs={22} md={7}>
        {!print && (
          <FilterSection>
            <Typography type="basic" elementType="p" size="1" uppercase>
              {UNION_FILTER}
            </Typography>
          </FilterSection>
        )}
        <Select
          size="large"
          allowClear
          onClear={() => {
            resetPaging();
            setLiittoValue('Kaikki');
          }}
          value={liittoValue || CHOOSE_UNION}
          onChange={(value) => {
            if (value && value !== liittoValue) {
              resetPaging();
            }
            setLiittoValue(value);
          }}
          style={{ width: '100%' }}
        >
          <Option value="Kaikki">{ALL}</Option>

          {Object.keys(liitot)
            .sort(sortUnionsByCurrentLanguage)
            .map((l) => (
              <Option value={l}>
                {CURRENT_LANG === 'fi'
                  ? liitot[l].fi
                  : CURRENT_LANG === 'sv'
                    ? liitot[l].sv
                    : liitot[l].en}
              </Option>
            ))}
        </Select>
      </Col>
    </Row>
  );
}

export default Filters;
