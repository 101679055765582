import { SET_PRINT_MODE } from './actions';

export const initialState = {};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRINT_MODE:
      return { ...state, printMode: action.payload };
    default:
      return state;
  }
};

export default reducer;
