export const CLICK_PRINT = 'CLICK_PRINT';
export const SET_PRINT_MODE = 'SET_PRINT_MODE';

export const clickPrint = () => ({
  type: CLICK_PRINT,
});

export const setPrintMode = (payload) => ({
  type: SET_PRINT_MODE,
  payload,
});
