import styled from '@emotion/styled';
import colors from './colors';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  max-width: 1600px;
  margin: 0 auto;

  .ant-list-split {
    .ant-list-item {
      &:first-of-type {
        border-top: 1px solid ${colors.gray04};
      }
      &:last-child {
        border-bottom: 1px solid ${colors.gray04};
      }
    }
  }
  .ant-row {
    margin-right: 0px !important;
    margin-top: 48px;

    &:first-of-type {
      margin-top: 0;
    }

    @media (max-width: 768px) {
      margin-top: 24px;
    }
  }

  .single-wrapper {
    margin-top: 48px;

    &:first-of-type {
      margin-top: 48px;
    }

    @media (max-width: 768px) {
      margin-top: 24px;
    }
  }

  .single-low-top {
    margin-top: 0;
  }

  .ant-card-bordered .ant-card-cover {
    margin-right: 0px;
    margin-left: 0px;
  }

  .ant-card-hoverable:hover {
    border: 1px solid ${colors.sakBlue};
    box-shadow:
      0px 6px 10px rgba(0, 118, 190, 0.03),
      0px 1px 18px rgba(0, 118, 190, 0.02),
      0px 3px 5px rgba(0, 118, 190, 0.04);
  }

  .ant-card-extra {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 16px;
    top: 16px;
    text-align: center;
    color: ${colors.gray09};
    font-weight: bold;
    border-radius: 100%;
    background: #d0ceb7;
  }

  .candidate {
    h1 {
      font-weight: 700;
    }
  }

  .single-wrapper {
    margin-top: 60px;
  }
  @media print {
    .ant-card {
    }
    .ant-card-cover {
      max-height: 6cm !important;
    }
    .ant-card-cover > * {
      max-height: 6cm !important;
    }
    .ant-card-small > .ant-card-head {
      font-size: 10px !important;
    }
    .ant-list-item-meta-title {
      font-size: 9px !important;
    }
    .ant-list-item {
      padding: 0 !important;
    }
  }
`;
