import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AutoComplete, Input, Radio } from 'antd';
import { useQuery } from '../../../helpers';
import { FilterSection } from '../styles';
import { getCandidates } from '../../Candidates/selectors';
import {
  NAME_SEARCH,
  MUNICIPALITY_SEARCH,
  MUNICIPALITY_SEARCH_HOLDER,
} from '../../../constants';
import kuntaData from './kunnat.json';

const getDefaultSearchType = ({ kuntaValue, searchValue }) => {
  if (kuntaValue) {
    return 'kunta';
  }
  if (searchValue) {
    return 'nimi';
  }
  return 'kunta';
};

/* eslint-disable react/prop-types */
function Search({
  setParentSearchValue,
  defaultSearchValue,
  defaultKuntaValue,
  setParentKuntaValue,
}) {
  const dataSource = useSelector(getCandidates);
  const [searchValue, setSearchValue] = useState(defaultSearchValue);
  const [kuntaValue, setKuntaValue] = useState(defaultKuntaValue);
  const [searchType, setSearchType] = useState(
    getDefaultSearchType({ kuntaValue, searchValue })
  );

  const query = useQuery();
  const { print } = query;

  const listat = kuntaData.map((ob, idx) => ({
    value: ob,
    label: ob,
    key: idx,
  }));

  const options = dataSource.map((ob, idx) => ({
    value: ob.title.rendered,
    label: ob.title.rendered,
    key: idx,
  }));
  return (
    <>
      {!print && (
        <FilterSection>
          <Radio.Group
            onChange={(e) => {
              setSearchType(e.target.value);
              setSearchValue(null);
              setKuntaValue(null);
              setParentKuntaValue(null);
              setParentSearchValue(null);
            }}
            value={searchType}
          >
            <Radio value="kunta">{MUNICIPALITY_SEARCH}</Radio>
            <Radio value="nimi">{NAME_SEARCH}</Radio>
          </Radio.Group>
        </FilterSection>
      )}
      {searchType === 'nimi' ? (
        <AutoComplete
          style={{
            width: '100%',
          }}
          value={searchValue}
          onChange={(value) => {
            setSearchValue(value);
            if (!value) {
              setParentSearchValue(null);
            }
          }}
          onSelect={(value) => {
            setParentSearchValue(value);
          }}
          defaultValue={options.find(
            (o) => searchValue && o.value === searchValue
          )}
          options={options}
          filterOption={(inputValue, option) =>
            option.label
              .toUpperCase()
              .indexOf(inputValue.toString().toUpperCase()) !== -1
          }
        >
          <Input.Search size="large" placeholder="Search" />
        </AutoComplete>
      ) : (
        <AutoComplete
          style={{
            width: '100%',
          }}
          defaultValue={listat.find(
            (o) => kuntaValue && o.value === kuntaValue
          )}
          onChange={(value) => {
            setKuntaValue(value);
            if (!value) {
              setParentKuntaValue(null);
            }
          }}
          onSelect={(value) => {
            setParentKuntaValue(value);
          }}
          options={listat}
          value={kuntaValue}
          filterOption={(inputValue, option) =>
            option.label
              .toUpperCase()
              .indexOf(inputValue.toString().toUpperCase()) !== -1
          }
        >
          <Input.Search size="large" placeholder={MUNICIPALITY_SEARCH_HOLDER} />
        </AutoComplete>
      )}
    </>
  );
}

export default Search;
