import React from 'react';
import { SvgWrapper, SocialShare } from '../Candidates/styles';

/* eslint-disable react/prop-types */

// This prevents react router "Link" from overriding "a" link
const stopEvent = (event) => event.stopPropagation();

function Social({ candidate }) {
  return (
    <SocialShare>
      {candidate.facebook && (
        <SvgWrapper>
          <a
            href={candidate.facebook}
            rel="noreferrer"
            target="_blank"
            onClick={stopEvent}
            aria-label="Facebook"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.625 0.625H1.375C0.960156 0.625 0.625 0.960156 0.625 1.375V18.625C0.625 19.0398 0.960156 19.375 1.375 19.375H18.625C19.0398 19.375 19.375 19.0398 19.375 18.625V1.375C19.375 0.960156 19.0398 0.625 18.625 0.625ZM16.4594 6.09766H14.9617C13.7875 6.09766 13.5602 6.65547 13.5602 7.47578V9.28281H16.3633L15.9977 12.1117H13.5602V19.375H10.6375V12.1141H8.19297V9.28281H10.6375V7.19687C10.6375 4.77578 12.1164 3.45625 14.2773 3.45625C15.3133 3.45625 16.2016 3.53359 16.4617 3.56875V6.09766H16.4594Z"
                fill="#83816f"
              />
            </svg>
          </a>
        </SvgWrapper>
      )}
      {candidate.twitter && (
        <SvgWrapper>
          <a
            href={candidate.twitter}
            rel="noreferrer"
            target="_blank"
            onClick={stopEvent}
            aria-label="X"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 300 300"
              fill="none"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M178.57 127.15 290.27 0h-26.46l-97.03 110.38L89.34 0H0l117.13 166.93L0 300.25h26.46l102.4-116.59 81.8 116.59h89.34M36.01 19.54H76.66l187.13 262.13h-40.66"
                fill="#83816f"
              />
            </svg>
          </a>
        </SvgWrapper>
      )}

      {candidate.instagram && (
        <SvgWrapper>
          <a
            href={candidate.instagram}
            rel="noreferrer"
            target="_blank"
            onClick={stopEvent}
            aria-label="Instagram"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.99913 6.87653C8.27882 6.87653 6.87492 8.28043 6.87492 10.0007C6.87492 11.7211 8.27882 13.125 9.99913 13.125C11.7194 13.125 13.1234 11.7211 13.1234 10.0007C13.1234 8.28043 11.7194 6.87653 9.99913 6.87653ZM19.3694 10.0007C19.3694 8.707 19.3812 7.42496 19.3085 6.13356C19.2359 4.63356 18.8937 3.30231 17.7968 2.20543C16.6976 1.10621 15.3687 0.766371 13.8687 0.693715C12.5749 0.621058 11.2929 0.632777 10.0015 0.632777C8.70773 0.632777 7.4257 0.621058 6.13429 0.693715C4.63429 0.766371 3.30304 1.10856 2.20617 2.20543C1.10695 3.30465 0.767103 4.63356 0.694447 6.13356C0.621791 7.42731 0.63351 8.70934 0.63351 10.0007C0.63351 11.2922 0.621791 12.5765 0.694447 13.8679C0.767103 15.3679 1.10929 16.6992 2.20617 17.7961C3.30538 18.8953 4.63429 19.2351 6.13429 19.3078C7.42804 19.3804 8.71007 19.3687 10.0015 19.3687C11.2952 19.3687 12.5773 19.3804 13.8687 19.3078C15.3687 19.2351 16.6999 18.8929 17.7968 17.7961C18.896 16.6968 19.2359 15.3679 19.3085 13.8679C19.3835 12.5765 19.3694 11.2945 19.3694 10.0007ZM9.99913 14.8078C7.33898 14.8078 5.1921 12.6609 5.1921 10.0007C5.1921 7.34059 7.33898 5.19371 9.99913 5.19371C12.6593 5.19371 14.8062 7.34059 14.8062 10.0007C14.8062 12.6609 12.6593 14.8078 9.99913 14.8078ZM15.003 6.1195C14.3819 6.1195 13.8804 5.61793 13.8804 4.99684C13.8804 4.37575 14.3819 3.87418 15.003 3.87418C15.6241 3.87418 16.1257 4.37575 16.1257 4.99684C16.1259 5.14432 16.097 5.29039 16.0406 5.42668C15.9843 5.56297 15.9016 5.68681 15.7973 5.79109C15.693 5.89538 15.5692 5.97806 15.4329 6.03442C15.2966 6.09077 15.1505 6.11968 15.003 6.1195Z"
                fill="#83816f"
              />
            </svg>
          </a>
        </SvgWrapper>
      )}

      {candidate.linkedin && (
        <SvgWrapper>
          <a
            href={candidate.linkedin}
            rel="noreferrer"
            target="_blank"
            onClick={stopEvent}
            aria-label="LinkedIn"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.625 0.625H1.375C0.960156 0.625 0.625 0.960156 0.625 1.375V18.625C0.625 19.0398 0.960156 19.375 1.375 19.375H18.625C19.0398 19.375 19.375 19.0398 19.375 18.625V1.375C19.375 0.960156 19.0398 0.625 18.625 0.625ZM6.18672 16.6023H3.40469V7.65391H6.18672V16.6023ZM4.79688 6.43047C4.47795 6.43047 4.16619 6.3359 3.90102 6.15871C3.63584 5.98153 3.42917 5.72969 3.30712 5.43505C3.18507 5.1404 3.15314 4.81618 3.21536 4.50339C3.27758 4.19059 3.43115 3.90327 3.65667 3.67776C3.88218 3.45225 4.1695 3.29867 4.48229 3.23645C4.79509 3.17423 5.11931 3.20617 5.41395 3.32821C5.7086 3.45026 5.96044 3.65694 6.13762 3.92211C6.3148 4.18729 6.40937 4.49905 6.40937 4.81797C6.40703 5.70859 5.68516 6.43047 4.79688 6.43047ZM16.6023 16.6023H13.8227V12.25C13.8227 11.2117 13.8039 9.87812 12.3766 9.87812C10.9305 9.87812 10.7078 11.0078 10.7078 12.175V16.6023H7.93047V7.65391H10.5977V8.87734H10.6352C11.0055 8.17422 11.9125 7.43125 13.2672 7.43125C16.0844 7.43125 16.6023 9.28516 16.6023 11.6945V16.6023Z"
                fill="#83816f"
              />
            </svg>
          </a>
        </SvgWrapper>
      )}

      {candidate.youtube && (
        <SvgWrapper>
          <a
            href={candidate.youtube}
            rel="noreferrer"
            target="_blank"
            onClick={stopEvent}
            aria-label="TikTok"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.49614 7.13176C9.18664 6.9549 8.80639 6.95617 8.49807 7.13509C8.18976 7.31401 8 7.64353 8 8V16C8 16.3565 8.18976 16.686 8.49807 16.8649C8.80639 17.0438 9.18664 17.0451 9.49614 16.8682L16.4961 12.8682C16.8077 12.6902 17 12.3589 17 12C17 11.6411 16.8077 11.3098 16.4961 11.1318L9.49614 7.13176ZM13.9844 12L10 14.2768V9.72318L13.9844 12Z"
                fill="#83816f"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 12C0 8.25027 0 6.3754 0.954915 5.06107C1.26331 4.6366 1.6366 4.26331 2.06107 3.95491C3.3754 3 5.25027 3 9 3H15C18.7497 3 20.6246 3 21.9389 3.95491C22.3634 4.26331 22.7367 4.6366 23.0451 5.06107C24 6.3754 24 8.25027 24 12C24 15.7497 24 17.6246 23.0451 18.9389C22.7367 19.3634 22.3634 19.7367 21.9389 20.0451C20.6246 21 18.7497 21 15 21H9C5.25027 21 3.3754 21 2.06107 20.0451C1.6366 19.7367 1.26331 19.3634 0.954915 18.9389C0 17.6246 0 15.7497 0 12ZM9 5H15C16.9194 5 18.1983 5.00275 19.1673 5.10773C20.0989 5.20866 20.504 5.38448 20.7634 5.57295C21.018 5.75799 21.242 5.98196 21.4271 6.23664C21.6155 6.49605 21.7913 6.90113 21.8923 7.83269C21.9973 8.80167 22 10.0806 22 12C22 13.9194 21.9973 15.1983 21.8923 16.1673C21.7913 17.0989 21.6155 17.504 21.4271 17.7634C21.242 18.018 21.018 18.242 20.7634 18.4271C20.504 18.6155 20.0989 18.7913 19.1673 18.8923C18.1983 18.9973 16.9194 19 15 19H9C7.08058 19 5.80167 18.9973 4.83269 18.8923C3.90113 18.7913 3.49605 18.6155 3.23664 18.4271C2.98196 18.242 2.75799 18.018 2.57295 17.7634C2.38448 17.504 2.20866 17.0989 2.10773 16.1673C2.00275 15.1983 2 13.9194 2 12C2 10.0806 2.00275 8.80167 2.10773 7.83269C2.20866 6.90113 2.38448 6.49605 2.57295 6.23664C2.75799 5.98196 2.98196 5.75799 3.23664 5.57295C3.49605 5.38448 3.90113 5.20866 4.83269 5.10773C5.80167 5.00275 7.08058 5 9 5Z"
                fill="#83816f"
              />
            </svg>
          </a>
        </SvgWrapper>
      )}

      {candidate.bluesky && (
        <SvgWrapper>
          <a
            href={candidate.bluesky}
            rel="noreferrer"
            target="_blank"
            onClick={stopEvent}
            aria-label="Bluesky"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 -3.268 64 68.414"
              fill="none"
              width="17"
              height="20"
            >
              <path
                fill="#83816f"
                d="M13.873 3.805C21.21 9.332 29.103 20.537 32 26.55v15.882c0-.338-.13.044-.41.867-1.512 4.456-7.418 21.847-20.923 7.944-7.111-7.32-3.819-14.64 9.125-16.85-7.405 1.264-15.73-.825-18.014-9.015C1.12 23.022 0 8.51 0 6.55 0-3.268 8.579-.182 13.873 3.805zm36.254 0C42.79 9.332 34.897 20.537 32 26.55v15.882c0-.338.13.044.41.867 1.512 4.456 7.418 21.847 20.923 7.944 7.111-7.32 3.819-14.64-9.125-16.85 7.405 1.264 15.73-.825 18.014-9.015C62.88 23.022 64 8.51 64 6.55c0-9.818-8.578-6.732-13.873-2.745z"
              />
            </svg>
          </a>
        </SvgWrapper>
      )}

      {candidate.tiktok && (
        <SvgWrapper>
          <a
            href={candidate.tiktok}
            rel="noreferrer"
            target="_blank"
            onClick={stopEvent}
            aria-label="TikTok"
          >
            <svg
              fill="none"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z"
                fill="#83816f"
              />
            </svg>
          </a>
        </SvgWrapper>
      )}

      {candidate.threads && (
        <SvgWrapper>
          <a
            href={candidate.threads}
            rel="noreferrer"
            target="_blank"
            onClick={stopEvent}
            aria-label="Threads"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 192 192"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="x19hqcy"
                d="M141.537 88.9883C140.71 88.5919 139.87 88.2104 139.019 87.8451C137.537 60.5382 122.616 44.905 97.5619 44.745C97.4484 44.7443 97.3355 44.7443 97.222 44.7443C82.2364 44.7443 69.7731 51.1409 62.102 62.7807L75.881 72.2328C81.6116 63.5383 90.6052 61.6848 97.2286 61.6848C97.3051 61.6848 97.3819 61.6848 97.4576 61.6855C105.707 61.7381 111.932 64.1366 115.961 68.814C118.893 72.2193 120.854 76.925 121.825 82.8638C114.511 81.6207 106.601 81.2385 98.145 81.7233C74.3247 83.0954 59.0111 96.9879 60.0396 116.292C60.5615 126.084 65.4397 134.508 73.775 140.011C80.8224 144.663 89.899 146.938 99.3323 146.423C111.79 145.74 121.563 140.987 128.381 132.296C133.559 125.696 136.834 117.143 138.28 106.366C144.217 109.949 148.617 114.664 151.047 120.332C155.179 129.967 155.42 145.8 142.501 158.708C131.182 170.016 117.576 174.908 97.0135 175.059C74.2042 174.89 56.9538 167.575 45.7381 153.317C35.2355 139.966 29.8077 120.682 29.6052 96C29.8077 71.3178 35.2355 52.0336 45.7381 38.6827C56.9538 24.4249 74.2039 17.11 97.0132 16.9405C119.988 17.1113 137.539 24.4614 149.184 38.788C154.894 45.8136 159.199 54.6488 162.037 64.9503L178.184 60.6422C174.744 47.9622 169.331 37.0357 161.965 27.974C147.036 9.60668 125.202 0.195148 97.0695 0H96.9569C68.8816 0.19447 47.2921 9.6418 32.7883 28.0793C19.8819 44.4864 13.2244 67.3157 13.0007 95.9325L13 96L13.0007 96.0675C13.2244 124.684 19.8819 147.514 32.7883 163.921C47.2921 182.358 68.8816 191.806 96.9569 192H97.0695C122.03 191.827 139.624 185.292 154.118 170.811C173.081 151.866 172.51 128.119 166.26 113.541C161.776 103.087 153.227 94.5962 141.537 88.9883ZM98.4405 129.507C88.0005 130.095 77.1544 125.409 76.6196 115.372C76.2232 107.93 81.9158 99.626 99.0812 98.6368C101.047 98.5234 102.976 98.468 104.871 98.468C111.106 98.468 116.939 99.0737 122.242 100.233C120.264 124.935 108.662 128.946 98.4405 129.507Z"
                fill="#83816f"
              />
            </svg>
          </a>
        </SvgWrapper>
      )}

      {candidate.candidatesite && (
        <SvgWrapper>
          <a
            href={candidate.candidatesite}
            rel="noreferrer"
            target="_blank"
            onClick={stopEvent}
            aria-label="Kotisivut"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 16H2V2H9V0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.89 18 2 18H16C17.1 18 18 17.1 18 16V9H16V16ZM11 0V2H14.59L4.76 11.83L6.17 13.24L16 3.41V7H18V0H11Z"
                fill="#83816f"
              />
            </svg>
          </a>
        </SvgWrapper>
      )}
    </SocialShare>
  );
}

export default Social;
