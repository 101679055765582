/**
 * Helper for creating actions
 * @param  {string} action type
 * @param  {Object} action payload
 * @return {Object} type Action
 */
// eslint-disable-next-line import/prefer-default-export
export const createAction = (type, payload) => ({
  type,
  payload,
});
