// eslint-disable-next-line react/prop-types
import { createStore, applyMiddleware, combineReducers } from 'redux';

import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import candidates from './components/Candidates/reducers';
import share from './components/Share/reducers';
import filters, {
  initialState as filtersInitialState,
} from './components/Filters/reducers';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

const initalState = {
  candidates: null,
  filters: filtersInitialState,
  share: { printMode: false },
};

const reducers = combineReducers({
  candidates,
  filters,
  share,
});

const initializeStore = (initialState = {}) => {
  const store = createStore(
    reducers,
    initialState,
    composeWithDevTools(applyMiddleware(sagaMiddleware))
  );

  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

export default initializeStore(initalState);
